import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './products.scss'
import Breadcrumb from './breadcrumb';
import { useParams } from 'react-router-dom';

type RouteParams = {
    id: string;
};

interface Idesc {
    point: string;
}

interface IProductDetails {
    id: string;
    title: string;
    price: string;
    img1: string;
    img2: string;
    img3: string;
    img4: string;
    desc: Idesc[];
}

const ProductDetails: React.FC = () => {
    const { id } = useParams<RouteParams>();
    const [productDetail, setProductDetail] = useState<IProductDetails>();
    const [imgSrc, setImgSrc] = useState<string>('https://images.unsplash.com/photo-1608634960479-c70cf0c3dece?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1374&q=80');

    const breadcrumbItems = [
        { text: 'Home', link: '/' },
        { text: 'Products', link: '/products' },
        { text: 'Buffalo Milk', active: true }
    ];

    useEffect(() => {
        axios.get(`${process.env.PUBLIC_URL}/product-details.json`)
            .then(response => {
                const foundProduct = response.data.find((product: IProductDetails) => product.id === id);
                if (foundProduct) {
                    setProductDetail(foundProduct);
                }
            })
            .catch(error => {
                console.error('Error loading data:', error);
            });
    }, [id]);


    const changeImage = (value: string) => {
        setImgSrc(value);
    }

    return (
        <div className='product-detail-section'>
            <div className='container mt-5'>
                <Breadcrumb items={breadcrumbItems} />
            </div>
            <div className="container details-wrapper">
                <div className="row">
                    <div className="col-md-6">
                        <img id="mainImage" src={imgSrc} className="img-fluid mb-3 main-img" />
                        <div className="d-flex">
                            <img src="https://cpimg.tistatic.com/06134588/b/4/250-ml-Baby-Milk-Bottle-With-Nipple.jpg" className="img-thumbnail me-2" onClick={() => changeImage("https://ajantabottle.com/public/storage/media/hhYkVu3J53V2oirAdaqI3cJ27BMWGOsFZxW8Nnco.jpeg")} />
                            <img src="https://cpimg.tistatic.com/06134588/b/4/250-ml-Baby-Milk-Bottle-With-Nipple.jpg" className="img-thumbnail me-2" onClick={() => changeImage("https://images.unsplash.com/photo-1608634960479-c70cf0c3dece?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1374&q=80")} />
                            <img src="https://cpimg.tistatic.com/06134588/b/4/250-ml-Baby-Milk-Bottle-With-Nipple.jpg" className="img-thumbnail me-2" onClick={() => changeImage("https://ajantabottle.com/public/storage/media/hhYkVu3J53V2oirAdaqI3cJ27BMWGOsFZxW8Nnco.jpeg")} />
                            <img src="https://cpimg.tistatic.com/06134588/b/4/250-ml-Baby-Milk-Bottle-With-Nipple.jpg" className="img-thumbnail me-2" onClick={() => changeImage("https://ajantabottle.com/public/storage/media/hhYkVu3J53V2oirAdaqI3cJ27BMWGOsFZxW8Nnco.jpeg")} />
                        </div>
                    </div>

                    <div className="col-md-6">
                        <h2>{productDetail?.title}</h2>
                        <p><i className="bi bi-currency-rupee"></i>{productDetail?.price}</p>
                        <div className="d-flex mb-3">
                            <div className="product-feature d-flex align-items-center">
                                <i className="fas fa-seedling green-icon mr-2"></i>No Added Preservatives
                            </div>
                            <div className="product-feature d-flex align-items-center">
                                <i className="fas fa-flask green-icon mr-2"></i>Chemical Free
                            </div>
                            <div className="product-feature d-flex align-items-center">
                                <i className="fas fa-check-circle green-icon mr-2"></i>Daily Tested
                            </div>
                        </div>

                        <p>This is a detailed description of the product. It provides insights about the product's features, usage, and any other relevant information.</p>
                        {/* <ul>
                            {productDetail?.desc.map((item: Idesc) => {
                                return (
                                    <li>{item.point}</li>
                                )
                            })}
                        </ul> */}
                        <button className='btn btn-success'>Order Now</button>
                    </div>

                </div>
            </div>

        </div>
    );
}

export default ProductDetails;
