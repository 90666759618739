import React from 'react';

interface FAQItem {
    question: string;
    answer: string;
}

const faqs: FAQItem[] = [
    {
        question: "What is your return policy?",
        answer: "We offer a 30-day return policy on all items, with a full refund. Some exclusions may apply."
    },
    {
        question: "How can I track my order?",
        answer: "You can track your order using the customer mobile application."
    },
    {
        question: "How do I get customer support?",
        answer: "For customer support, please contact us at +91-8168024591 or email us at ydv.anil84@gmail.com."
    }
];

const FAQ: React.FC = () => {
    return (
        <section className="container my-5">
            <h2 className="text-center mb-4">Frequently Asked Questions</h2>
            <p>
            Our Frequently Asked Questions (FAQs) section aims to provide clear, concise answers to the most common queries we receive. 
            If you can't find the answers you're looking for here, please don't hesitate to reach out directly. We're always here to assist and empower you with the information you need
            </p>
            <div className="accordion" id="faqAccordion">
                {faqs.map((faq, index) => (
                    <div key={index} className="accordion-item">
                        <h2 className="accordion-header" id={`heading${index}`}>
                            <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${index}`} aria-expanded="true" aria-controls={`collapse${index}`}>
                                {faq.question}
                            </button>
                        </h2>
                        <div id={`collapse${index}`} className="accordion-collapse collapse" aria-labelledby={`heading${index}`} data-bs-parent="#faqAccordion">
                            <div className="accordion-body">
                                {faq.answer}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
}

export default FAQ;
