import React from 'react';

interface BreadcrumbItem {
  text: string;
  link?: string;
  active?: boolean;
}

interface BreadcrumbProps {
  items: BreadcrumbItem[];
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ items }) => {
  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        {items.map((item, index) => (
          <li key={index} className={`breadcrumb-item ${item.active ? 'active' : ''}`}>
            {item.link ? <a href={item.link}>{item.text}</a> : item.text}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
