import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './products.scss'

interface IProduct {
    id: number;
    title: string;
    subTitle: string;
    price: string;
    qty: string;
    desc: string;
    img: string;
}

const Products: React.FC = () => {
    const navigate = useNavigate();
    const [products, setProducts] = useState<IProduct[]>();

    const openShop = () => {
        window.open('https://jeevandhara.trakop.com')
    }

    const showProductDetail = (value: number) => {
        navigate(`/product-details/${value}`);
    }

    useEffect(() => {
        axios.get(`${process.env.PUBLIC_URL}/products.json`)
            .then(response => {
                setProducts(response.data);
            })
            .catch(error => {
                console.error('Error loading data:', error);
            });
    }, []);

    return (
        <div className='products-section'>
            <div className="banner-gradient">
                <h1>Welcome to our Store</h1>
                <p>Quality products you can trust</p>
            </div>
            <div className="container mt-5">
                <div className="row">
                    {products && products.map((item: IProduct) => {
                        return (
                            <>
                                <div onClick={() => showProductDetail(item.id)} className="col-md-6">
                                    <div className="card p-3">
                                        <div className="card-content">
                                            <img src={item.img} className="card-img" alt="..." />
                                            <div>
                                                <h6 className="card-title">{item.title}</h6>
                                                <span style={{fontSize: '12px', color: '#343030'}}>( {item.subTitle} )</span>
                                                <p style={{marginTop: '10px'}}>Price: <i className="bi bi-currency-rupee"></i>{item.price}</p>
                                                <p>Qty: {item.qty}</p>
                                            </div>
                                        </div>
                                        <div className="card-description">
                                            <p className="card-text">{item.desc}</p>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )
                    })}

                    <button onClick={openShop} className='btn btn-success order-btn'>Order Now</button>
                </div>
            </div>
        </div>
    );
}

export default Products;
