import React from 'react';
import './contact.scss';
import FAQ from './faq';

const Contact: React.FC = () => {
    return (
        <div className='contact-section'>
            <section className="banner-gradient">
                <h1>Your Feedback Matters</h1>
            </section>
            <section className="container">
                <h2 className="text-center my-4">How can we Help?</h2>
                <p>At Purity Harvest, we're dedicated to ensuring your experience with us is seamless and satisfying. Whether you have questions about our products, need technical support, or just want to learn more, our proficient team is always at the ready. Let us know how we can be of service, and we'll be there every step of the way.</p>
                <div className="row icon-section">
                    <div className="col-md-4">
                        <div className="icon-box">
                            <i className="bi bi-telephone-fill"></i>
                            <h4>Customer Support Helpline</h4>
                            <p>+91-8168024581</p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="icon-box">
                        <i className="bi bi-clock"></i> 
                            <h4>Operating Hours</h4>
                            <p>Mon-Sun: 9am - 6pm</p>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="icon-box">
                            <i className="bi bi-envelope-fill"></i>
                            <h4>Email ID</h4>
                            <p>ydv.anil84@gmail.com</p>
                        </div>
                    </div>
                </div>
                <FAQ />
            </section>
        </div>
    );
}

export default Contact;
