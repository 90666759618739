import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo.png';

const Footer: React.FC = () => {
    return (
        <footer className="footer-curve">
            <div className="container">
                <div className="grid-container">
                    <div>
                    <Link to="/" className="logo"><img src={logo} /></Link><br /><br />
                        <p>At Purity Harvest, we ensure the freshest and highest quality milk reaches you. Quality and sustainability are our watchwords.</p>
                    </div>
                    <div>
                        <h5>Quick Links</h5>
                        <ul className="list-unstyled">
                            <li><a href="#" className="footer-link">Home</a></li>
                            <li><a href="#" className="footer-link">About Us</a></li>
                            <li><a href="#" className="footer-link">Shop</a></li>
                        </ul>
                    </div>
                    <div>
                        <h5>Support</h5>
                        <ul className="list-unstyled">
                            <li><a href="#" className="footer-link">Contact Us</a></li>
                            <li><a href="#" className="footer-link">Privacy Policy</a></li>
                        </ul>
                    </div>
                    <div>
                        <h5>Connect With Us</h5>
                        <a href="#" className="text-white"><i className="fab fa-facebook-f social-icon"></i></a>
                        <a href="#" className="text-white"><i className="fab fa-twitter social-icon"></i></a>
                        <a href="#" className="text-white"><i className="fab fa-instagram social-icon"></i></a>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col text-center">
                        <h5>Download Our App</h5>
                        <a  href="https://apps.apple.com/in/app/purity-harvest/id1671720908" target='_blank' className="btn btn-light btn-sm me-2">iOS App</a>
                        <a href="https://play.google.com/store/apps/details?id=com.jeevandhara.user" target='_blank' className="btn btn-light btn-sm">Android App</a>
                    </div>
                </div>
                <div className="row mt-5">
                    <div className="col text-center">
                        <p>&copy; 2024 Purity Harvest. All rights reserved.</p>
                    </div>
                </div>
            </div>
        </footer>

    );
}

export default Footer;
