import React from 'react';
import './about.scss';

const About: React.FC = () => {
    return (
        <div className='about-section'>
            <div className="banner-gradient">
                <h1>Welcome to our Dairy Paradise</h1>
                <p>Discover more about our organization and its vision</p>
            </div>
            <div className="container">
                <section className="about-us">
                    <p className="card-text mt-3 ">
                        Our remarkable journey began roughly 13 years ago when we established our dairy farm, initially starting with just 5 cows. Fast forward to today, and we are proud to say that our farm is now home to more than 80 cows. <br /><br />

                        From our early beginnings until 2015, we devoted ourselves to learning the nuances of dairy farming, exploring various facets to understand the most effective and ethical practices for producing high-quality milk. This period of dedicated learning was crucial in laying the foundation for what was to come.<br /><br />

                        In 2016, we took a bold step forward by introducing doorstep milk delivery services. Our journey into this new endeavor commenced with the use of conventional milk cans for delivery. However, as our farm and delivery service evolved, we came to realize the numerous benefits of glass bottles for milk delivery. Consequently, we made the strategic decision to transition to glass packaging, prioritizing both the freshness and sustainability of our products.<br /><br />

                        Beyond our own farm, we also embarked on a mission to support our community by procuring buffalo milk from trusted local farmers. This collaboration not only strengthened our ties with our fellow farmers but also contributed to the well-being of our community at large.<br /><br />

                        Today, we have meticulously honed a comprehensive and efficient method that spans the entire journey from milk procurement to doorstep delivery. This method includes rigorous testing, precise chilling, and secure packaging, all designed to ensure that only the highest quality dairy products reach our customers.<br /><br />

                        We take immense pride in our journey, from those humble beginnings of learning to becoming a trusted source of pure, wholesome dairy goodness for our community. And as we continue to grow, we are steadily expanding our product range to offer even more fresh and exceptional options to our valued customers<br />
                    </p>
                </section>
                <section className="mb-5">
                    <h2 style={{textAlign: 'center'}} className="mb-5">Why Choose Us</h2>
                    <div className="row">
                        <div className="col-md-4 mb-4">
                            <div className="card feature-card">
                                <div className="card-img-top">
                                <i className="fas fa-medal fa-3x"></i>
                                </div>
                                <div className="card-body">
                                    <h6 className="card-title">High Quality Service</h6>
                                    <p className="card-text mt-3">We are committed to delivering unparalleled high-quality service that consistently exceeds our customers' expectations, ensuring excellence and satisfaction at every touchpoint.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mb-4">
                            <div className="card feature-card">
                                <div className="card-img-top">
                                      <i className="fas fa-users fa-3x"></i>
                                </div>
                                <div className="card-body">
                                    <h6 className="card-title">Experienced Professionals</h6>
                                    <p className="card-text mt-3">Our team boasts over 13 years of professional experience in dairy farming and its products, bringing unmatched expertise and a deep understanding of quality and innovation to the industry.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 mb-4">
                            <div className="card feature-card">
                                <div className="card-img-top">
                                <i className="fas fa-tags fa-3x"></i>
                                </div>
                                <div className="card-body">
                                    <h6 className="card-title">Competitive Pricing</h6>
                                    <p className="card-text mt-3">We offer our products at competitive prices, ensuring that our customers receive exceptional value without compromising on quality or performance.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default About;
