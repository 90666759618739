import React from 'react';
import './carousel.scss';
import { Link } from 'react-router-dom';
import image01 from '../../assets/images/products/01.png';
import image02 from '../../assets/images/products/02.png';
import image03 from '../../assets/images/products/03.png';
import image04 from '../../assets/images/products/04.png';
import image05 from '../../assets/images/products/05.png';

const Carousel: React.FC = () => {
    return (
        // <div className='carousel-section'>
        //     <div className="banner">
        //         <h1 className="banner-heading">Where the happy Cattels Creates Blissful Dairy Products</h1>
        //         <p className="banner-subheading">Sub Heading</p>
        //         <Link to='products' className="banner-btn">Explore Our Products</Link>
        //     </div>
        // </div>
        <div id="myCarousel" className="carousel slide" data-bs-ride="carousel">
            <ul className="carousel-indicators">
                <li data-bs-target="#myCarousel" data-bs-slide-to="0" className="active"></li>
                <li data-bs-target="#myCarousel" data-bs-slide-to="1"></li>
                <li data-bs-target="#myCarousel" data-bs-slide-to="2"></li>
                <li data-bs-target="#myCarousel" data-bs-slide-to="3"></li>
                <li data-bs-target="#myCarousel" data-bs-slide-to="4"></li>
            </ul>
            <div className="carousel-inner">
                <div className="carousel-item active">
                    <img src={image01} alt="Product" className="d-block w-100" />
                    {/* <div className="carousel-caption">
                        <h3>Where the happy Cattels Creates Blissful Dairy Products</h3>
                        <a href="#" className="btn btn-primary">Learn More</a>
                    </div> */}
                </div>
                <div className="carousel-item">
                    <img src={image02} alt="Product" className="d-block w-100"/>
                    {/* <div className="carousel-caption">
                        <h3>First slide label</h3>
                        <p>Some description for the first slide.</p>
                        <a href="#" className="btn btn-primary">Learn More</a>
                    </div> */}
                </div>
                <div className="carousel-item">
                    <img src={image03} alt="Product" className="d-block w-100" />
                    {/* <div className="carousel-caption">
                        <h3>First slide label</h3>
                        <p>Some description for the first slide.</p>
                        <a href="#" className="btn btn-primary">Learn More</a>
                    </div> */}
                </div>
                <div className="carousel-item">
                    <img src={image04} alt="Product" className="d-block w-100" />
                    {/* <div className="carousel-caption">
                        <h3>First slide label</h3>
                        <p>Some description for the first slide.</p>
                        <a href="#" className="btn btn-primary">Learn More</a>
                    </div> */}
                </div>
                <div className="carousel-item">
                    <img src={image05} alt="Product" className="d-block w-100" />
                    {/* <div className="carousel-caption">
                        <h3>First slide label</h3>
                        <p>Some description for the first slide.</p>
                        <a href="#" className="btn btn-primary">Learn More</a>
                    </div> */}
                </div>
            </div>
            <a className="carousel-control-prev" href="#myCarousel" role="button" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </a>
            <a className="carousel-control-next" href="#myCarousel" role="button" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </a>
        </div>
    );
}

export default Carousel;
