import React from 'react';
import { Link } from 'react-router-dom';

const ExploreRange: React.FC = () => {
    return (
        <section className="container mt-5">
            <h2 className="text-center mb-4">Explore Our Range</h2>
            <p className='process-subheading'>The process of delivering milk from the farm to a consumer's doorstep involves several critical steps, ensuring that the milk remains fresh, safe, and of the highest quality. Here's a brief overview of the process:</p>
            <img className="img-fluid explore-img" src='https://www.pngkey.com/png/detail/702-7029407_groceries-png.png' />
            <Link to='products' className='btn btn-success view-products'>View All Products</Link>
        </section>
    );
}

export default ExploreRange;
