import React from 'react';
import { Link } from 'react-router-dom';

import logo from '../../assets/images/logo.png';

const Header: React.FC = () => {
    return (
        <>
            {/* Top fixed logo for mobile view */}
            <div className="mobile-logo d-lg-none fixed-top text-center py-2">
                <Link to="/" className="logo"><img src={logo} /></Link>
            </div>

            {/* Bottom fixed navigation for mobile view */}
            <div className="mobile-nav d-lg-none fixed-bottom bg-light shadow-sm py-2">
                <div className="container d-flex justify-content-between">
                    <Link to="/" className="mx-2 mob-nav"> <i className="fas fa-home"></i> Home</Link>
                    <Link to="about" className="mx-2 mob-nav"> <i className="fas fa-info-circle"></i> About</Link>
                    <Link to="contact" className="mx-2 mob-nav">  <i className="fas fa-envelope"></i> Contact</Link>
                </div>
            </div>

            {/* Regular navbar for larger screens */}
            <header className="main-header fixed-header d-none d-lg-flex justify-content-between align-items-center">
                <Link to="/" className="logo"><img src={logo} /></Link>
                <nav className="main-nav d-flex">
                    <Link to="about" className="mx-2">About</Link>
                    <Link to="products" className="mx-2">Products</Link>
                    <Link to="contact" className="mx-2">Contact</Link>
                    <a href="#" className="mx-2"><button className='btn btn-success'>Book Milk Trial</button></a>
                </nav>
            </header>
        </>
    );
}

export default Header;
