import Carousel from './components/carousel/carousel';
import Process from './components/process/process';
import ExploreRange from './components/explore-range/exploreRange';
import Testimonial from './components/testimonials/testimonial';

function App() {
    return (
        <div className="App">
            <Carousel />
            <Process />
            <ExploreRange />
            <Testimonial />
        </div>
    );
}

export default App;
