import React from 'react';
import './process.scss';

const Process: React.FC = () => {
    return (
        <section className="container mt-5 process-section">
            <h3 className="text-center mb-4">Ensuring The Purest And Most Delicious Dairy Experience</h3>
            <p className='process-subheading'>From the pristine dairy farms to your doorstep, we take pride in collecting the freshest milk and delivering it directly to our valued customers, ensuring quality and goodness in every drop.</p>
            <div className="row">
                <div className="col-md-3">
                    <div className="card mb-4 text-center d-flex flex-column modern-card">
                        <div className="fa-3x text-muted m-3">
                            <i className="fas fa-hand-holding-water"></i>
                        </div>
                        <div className="card-body mt-auto">
                            <h6 className="card-title">Step 1: Milking at Dawn and Dusk</h6>
                            <p className="card-text process-desc">Our farmers embark on the essential task of milking, providing a consistent source of wholesome milk</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="card mb-4 text-center d-flex flex-column modern-card">
                        <div className="fa-3x text-muted m-3">
                            <i className="fas fa-vial"></i>
                        </div>
                        <div className="card-body mt-auto">
                            <h6 className="card-title">Step 2: Testing the Purity & Quality</h6>
                            <p className="card-text process-desc">We uphold our commitment to quality by conducting daily milk testing procedures</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="card mb-4 text-center d-flex flex-column modern-card">
                        <div className="fa-3x text-muted m-3">
                            <i className="fas fa-temperature-low"></i>
                        </div>
                        <div className="card-body mt-auto">
                            <h6 className="card-title">Step 3: Preserving Milk's Freshness</h6>
                            <p className="card-text process-desc">We improves its shelf life by cooling it to 4 degrees Celsius before packaging it for delivery </p>
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                    <div className="card mb-4 text-center d-flex flex-column modern-card">
                        <div className="fa-3x text-muted m-3">
                            <i className="fas fa-truck"></i>
                        </div>
                        <div className="card-body mt-auto">
                            <h6 className="card-title">Step 4: Guaranteed Timely Delivery</h6>
                            <p className="card-text process-desc">Our Delivery Partners Ensure Fresh Milk Reaches You Within Hours of Packaging</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Process;
